import { createAsyncThunk } from '@reduxjs/toolkit';
import { Interaction, Experiment } from '@constants';
import type { SavedPaymentMethodWithMeta } from '@appTypes';
import { concatWithServiceMetaTypes } from '@utils/paymentMethodMapper';
import { WarmupDataKey } from '@services/WarmupData';
import type { ThunkApiConfig } from '../../store.types';
import type { FetchThunk } from './types';

export const fetchPaymentMethodTypes = createAsyncThunk<
  SavedPaymentMethodWithMeta[] | undefined,
  FetchThunk | undefined,
  ThunkApiConfig
>(
  'paymentMethodTypes/fetch',
  async ({ demoView } = {}, { extra, getState, rejectWithValue }) => {
    const { paymentMethodsListApi, warmupData, flowAPI } = extra;
    const { environment, fedops, errorMonitor, errorHandler, experiments } =
      flowAPI;

    fedops.interactionStarted(Interaction.LoadSitePaymentMethodTypes);

    try {
      const { savedPaymentMethods } = getState().savedPaymentMethods;

      if (!environment?.isViewer) {
        return savedPaymentMethods;
      }

      if (
        demoView ||
        !savedPaymentMethods?.length ||
        !experiments.enabled(Experiment.UseSitePaymentMethodTypes)
      ) {
        fedops.interactionEnded(Interaction.LoadSitePaymentMethodTypes);
        return savedPaymentMethods;
      }

      const paymentMethodTypeIds = savedPaymentMethods.map(
        ({ paymentMethod }) =>
          paymentMethod?.paymentMethodBrandId ||
          paymentMethod?.paymentMethodTypeId ||
          '',
      );

      const sitePaymentMethodTypes =
        await paymentMethodsListApi.getSitePaymentMethodTypes(
          paymentMethodTypeIds,
        );

      fedops.interactionEnded(Interaction.LoadSitePaymentMethodTypes);
      const methodsWithTypes = concatWithServiceMetaTypes(
        savedPaymentMethods,
        sitePaymentMethodTypes,
      );
      warmupData.set(WarmupDataKey.SavedPaymentMethods, methodsWithTypes);
      return methodsWithTypes;
    } catch (error) {
      const resolvedError = errorHandler.getResolvedError(error);

      console.error('[my-wallet]:', resolvedError);

      if (error instanceof Error) {
        errorMonitor.captureException(error);
      }

      throw rejectWithValue({ error });
    }
  },
);
